








import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "DrawerToggle",
  computed: {
    mini() {
      return this.$store.getters.mini;
    },
  },
  methods: {
    setMini() {
      this.$store.dispatch("toggleMini");
    },
  },
});
